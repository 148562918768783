import Vue from 'vue'
import Router from 'vue-router'
import Main from './views/Main.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'Main', component: Main, meta: { requires_auth: false } },
    {
      path: '/scatter', name: 'Scatter', meta: { requires_auth: true },
      component: () => import(/* webpackChunkName: "scatter" */ './views/Scatter.vue')
    },
    {
      path: '/bar', name: 'Bar', meta: { requires_auth: true },
      component: () => import(/* webpackChunkName: "bar" */ './views/Bar.vue')
    },
    {
      path: '/tree', name: 'Tree', meta: { requires_auth: true },
      component: () => import(/* webpackChunkName: "tree" */ './views/Tree.vue')
    },
    {
      path: '/pie', name: 'Pie', meta: { requires_auth: true },
      component: () => import(/* webpackChunkName: "pie" */ './views/Pie.vue')
    },
    {
      path: '/histogram', name: 'Histogram', meta: { requires_auth: true },
      component: () => import(/* webpackChunkName: "histogram" */ './views/Histogram.vue')
    },
  ]
})
