<style lang="sass">
  .application,
  .application>.application--wrap,
  .application>.application--wrap>.v-content,
  .application>.application--wrap>.v-content>.v-content__wrap
    height: 100%
</style>

<template>
  <v-app dark style="background-color: #395137;">
    <v-tabs v-model="active_tab" color="green" slider-color="yellow">
      <v-tab v-for="tab in allowed_tabs" :key="tabs.indexOf(tab)" ripple :to="{path: tab.path}">
        <span class="white--text">{{ tab.name }}</span>
      </v-tab>

      <v-spacer></v-spacer>

      <div v-if="user" style="padding-top: 6px">
        <v-tooltip bottom color="#222" style="cursor: help">
          <v-icon slot="activator" large color="white">account_box</v-icon>
          <div>{{ user.login }}</div>
        </v-tooltip>
      </div>

      <v-btn v-if="user" color="accent" @click="logout">Logout</v-btn>

      <v-dialog v-else v-model="auth_dialog" color="accent" max-width="400px">
        <v-btn slot="activator" color="accent" dark>Login</v-btn>
        <v-card v-if="auth_dialog">
          <v-card-title>
            <span class="headline">Authorization</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field label="Login"
                                v-model="auth_dialog_data.login"
                                @keypress.enter="login"
                                autofocus
                                required>
                  </v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field label="Password" type="password"
                                v-model="auth_dialog_data.password"
                                @keypress.enter="login"
                                required>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-alert v-if="auth_error" :value="true" type="error" color="red darken-3">
                Invalid username/password combination.
              </v-alert>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="auth_dialog = false">Close</v-btn>
            <v-btn color="primary" flat @click="login">Enter</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-tabs>

    <v-content ref="container" v-bind:class="cls">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import router from './router'

  let self = {};
  export default {
    name: 'App',
    created(){
      self = this;
    },
    mounted(){
      router.beforeEach((to, from, next) => {
        if( to.matched.some(record => record.meta.requires_auth) && !self.user ) {
          next({ path: '/' });
        } else {
          next();
        }
      });

      if( !self.user ) router.push('/');
    },
    data () {
      return {
        user: window.user,
        auth_dialog: false,
        auth_error: false,
        auth_dialog_data: {
          login: '',
          password: '',
        },

        active_tab: '',
        tabs: router.options.routes,
      }
    },
    computed: {
      cls(){
        return this.$route.path === '/' ? 'main-page' : '';
      },
      allowed_tabs(){
        return self.tabs.filter((tab)=>{
          return !tab.meta.requires_auth || self.user;
        });
      }
    },
    methods: {
      login(){
        const form = new FormData();
        form.set('login', self.auth_dialog_data.login);
        form.set('password', self.auth_dialog_data.password);

        axios({
          method: 'post',
          url: '/login/',
          data: form,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then((response)=>{
          if( response.status === 200 && response.data ){
            self.user = response.data;
            self.auth_dialog = false;
            self.auth_error = false;
          }
        }).catch(()=>{
          self.auth_error = true;
        });
      },
      logout(){
        axios.get('/logout/')
          .then(()=>{})
          .catch(()=>{});

        self.user = null;
        router.push('/');
      }
    }
  }
</script>
