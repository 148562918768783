<style>
  .main-page{
    background: linear-gradient(to bottom, #395137 5%, #333 60%, #14141c 90%);
  }
</style>

<style scoped>
  .container{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 90% !important;
    max-width: 100%;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to bottom, #395137 5%, #17324a 30%, #17324a 52%, #132539 70%,
                                           #14141c 100%);
  }

  .logo{
    flex: 0 1 auto;
    align-self: center;
    padding: 0 0 5%;
    margin: 0;
    width: 100%;

    text-align: center;
    font-size: 5rem;
    font-family: 'Helvetica', serif;
    letter-spacing: 10px;
    text-shadow: 1px 1px 2px black;
  }

  .logo span{
    color: #FF0000;
    text-shadow: 1px 1px 2px black;
  }
</style>

<template>
  <div class="container">
    <div class="logo">
      MEDIA<span>INSTINCT</span>GROUP
    </div>
  </div>
</template>

<script>
  export default {
    data: () => ({

    })
  }
</script>